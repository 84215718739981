import React, { Component } from 'react'
import Gamepad from 'react-gamepad'

import PubSub from './PubSub.js'
import './App.css'
var te
class App extends Component {
  constructor(props) {
    super(props)
    te = this
    this.state = {
      gamepadConnect: false
    }
  }
  connectHandler(gamepadIndex) {
    te.setState({
      gamepadConnect: true
    })
    console.log(`Gamepad ${gamepadIndex} connected !`)
  }
 
  disconnectHandler(gamepadIndex) {
    te.setState({
      gamepadConnect: false
    })
    console.log(`Gamepad ${gamepadIndex} disconnected !`)
  }
  soundboard1() {
    te.soundboard(1)
  }
  soundboard2() {
    te.soundboard(2)
  }
  soundboard3() {
    te.soundboard(3)
  }
  soundboard4() {
    te.soundboard(4)
  }
  soundboard5() {
    te.soundboard(5)
  }
  soundboard6() {
    te.soundboard(6)
  }
  soundboard7() {
    te.soundboard(7)
  }
  soundboard8() {
    te.soundboard(8)
  }
  soundboard9() {
    te.soundboard(9)
  }
  soundboard10() {
    te.soundboard(10)
  }
  soundboard11() {
    te.soundboard(11)
  }
  soundboard12() {
    te.soundboard(12)
  }
  soundboard(int) {
    PubSub.broadcast('/api/soundboard', { soundId: (int-1)})
  }
  render() {
    return (
      <div>
       <Gamepad
        onConnect={this.connectHandler}
        onDisconnect={this.disconnectHandler}

        onA={this.soundboard1}
        onB={this.soundboard2}
        onX={this.soundboard3}
        onY={this.soundboard4}
        onStart={this.soundboard5}
        onBack={this.soundboard6}
        onLT={this.soundboard7}
        onRT={this.soundboard8}
        onLB={this.soundboard9}
        onRB={this.soundboard10}
        onLS={this.soundboard11}
        onRS={this.soundboard12}

        ><p>hi im the soundboard dont minimize or switch tabs from this</p></Gamepad>
        <div>I am currently {this.state.gamepadConnect ? "connected" : "disconnected"}</div>
      </div>
    )
  }
}
export default App